<template>
  <section>
    <div class="xl:container mx-auto p-3">
      <h3 class="dark:text-white text-3xl font-serif">Over ons pagina</h3>
    </div>
  </section>
</template>

<script>
export default {
  name: "About",
};
</script>

<style></style>
